export const finishUp = {
  landing: {
    title: 'Your North One debit card will take 7-10 days to arrive by mail',
    subtitle: 'Confirm which shipping address we should send your North One debit card to.',
    sameMailingAddressSubtitle: 'Your card will be sent to {{mailingAddress}}',
    manualReviewLanding: {
      nextUp: 'Next up:',
      title: 'Finish up your application',
      subtitle: "You'll need the following information:",
      takePhotos: 'Take photos of the front and back of your ID',
      formationOfDocuments: 'Formation or incorporation documentation',
      businessLicense: 'Business license or Doing Business As (DBA)',
    },
  },

  debitCardAddress: {
    title: 'Where would you like your new debit card to be sent?',
    subtitle:
      'Your debit card will be delivered within {{cardShippingTime}} business days of opening your North One Account.',
    businessAddress: 'Business address',
    homeAddress: 'Home address',
    shippingAddress: 'Shipping address',
    submittingYourSelection: 'Submitting your selection',
  },

  welcomeToNorthOne: {
    title: 'Welcome to North One',
    scheduleACallNote:
      'Congratulations! Schedule your North One onboarding webinar to get tips on how to best set up your Account.',
    scheduleACallButton: 'Schedule your webinar',
    skipCallButton: "It's ok, I'll skip this step",
  },

  additionalOwnerWelcomeToNorthOne: {
    title: 'Welcome to North One',
    note: 'Your North One Mastercard® Small Business debit card will arrive within 10 business days. It will take 3 business days for your funds to arrive in your account.',
    getStarted: 'Get started',
  },
} as const
